import React from "react";
import TrackVisibility from "react-on-screen";
import Skill from "./Skill";


function SkillsGroup({className, progressData}) {

  const classname = `${className} container`

  return (
    <div className={classname}>
      <div className="row">
        <div className="triangle-left-md triangle-top-sm">
          <div className="rounded bg-white shadow-dark padding-30">
            <div className="row">
              {progressData.map((progress) => (
                <TrackVisibility
                  once
                  key={progress.id}
                  className="progress-wrapper"
                >
                  <Skill progress={progress} />
                </TrackVisibility>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="spacer" data-height="70"></div>
    </div>
  );
}

export default SkillsGroup;

import React from "react";
import Pagetitle from "../elements/Pagetitle";
import SkillsGroup from "../elements/SkillsGroup";

const progressData = [
  {
    id: 1,
    title: "Java",
    percantage: 90,
    progressColor: "#FFD15C",
  },
  {
    id: 2,
    title: "JavaScript",
    percantage: 75,
    progressColor: "#FF4C60",
  },
  {
    id: 3,
    title: "Angular",
    percantage: 80,
    progressColor: "#6C6CE5",
  },
  {
    id: 4,
    title: "TypeScript",
    percantage: 90,
    progressColor: "#FFD15C",
  },
  {
    id: 5,
    title: "NgRx",
    percantage: 75,
    progressColor: "#FF4C60",
  },
  {
    id: 6,
    title: "RxJS",
    percantage: 80,
    progressColor: "#6C6CE5",
  },
];

function Skills() {
  return (
    <section id="skills">
      <div className="container">
        <Pagetitle title="Skills" />

        <div className="row">
          <SkillsGroup
            className="col-md-6"
            progressData={progressData.slice(0,3)}
          />
          <SkillsGroup
            className="col-md-6"
            progressData={progressData.slice(3)}
          />
        </div>

      </div>
    </section>
  );
}

export default Skills;

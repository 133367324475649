import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
  {
    id: 1,
    title: "Master's Degree - Telecommunications",
    years: "2003 - 2008",
    content:
      "National Institute of Applied Sciences (INSA) of Lyon",
  },
  {
    id: 2,
    title: "Exchange Program - Information Technology",
    years: "2007",
    content:
      "Lappeenranta University of Technology",
  },
  {
    id: 3,
    title: "French Baccalaureate in Science with Honours",
    years: "1996 - 2003",
    content:
      "Lycée Lyautey",
  },
];

const experienceData = [
  {
    id: 1,
    title: "Technical Lead @ Amadeus",
    years: "2015 - Current - Dubai, United Arab Emirates",
    content:
      "Development of the Online Booking Systems of Saudi Airlines (Java - Spring - JavaScript - Angular - TypeScript - NgRx - RxJS).",
  },
  {
    id: 2,
    title: "Senior Software Engineer @ GFI Informatique",
    years: "2009 - 2015 - Casablanca, Morocco",
    content:
      "Development of the Self Care System of Orange Group / DataWarehouse System of Bouygues Telecom / eCommerce System of La Poste & Conforama.",
  },
  {
    id: 3,
    title: "Software Developer @ Atos",
    years: "2008 - Lyon, France",
    content:
      "Development of Carrefour Group gas stations management system.",
  },
  {
    id: 4,
    title: "Research Intern @ Lappeenranta University of Technology",
    years: "2007 - Lappeenranta, Finland",
    content:
      "Design of a Bluetooth-Wifi-GPRS Handoff System.",
  },
];

function Experiences() {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Experience" />
        <div className="row">
          <div className="col-md-6">
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>

          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
